import BasePopup from "../../../../../tools/components/general/BasePopup";
import CategorizerManager from "../../../../../tools/components/general/Categorizer";
import Comment from "./grids/Comment";
import Dates from "./grids/Dates";
import Identification from "./grids/Identification";
import React, { useEffect, useState } from "react";
import { server_host, ServerProps} from "../../../../../config/server.config";
import Totals from "./grids/Totals";
import { Icons, ObjectUtils } from "tc-minibox";
import MainButton from "../../../../../tools/components/general/MainButton";
import { useDispatch, useSelector } from "react-redux";
import { addRequestStatus, setLoaderStatus } from "../../../../../store/global/actions.global";
import { checkInvStatus } from "../deposit/utils.deposit";
import { account_user } from "../../../../../store/account/selector.account";
import SupplierManager from "../../../../suppliers/manager/Manager";
import moment from "moment";
import ContainerLoad from "../../../../../tools/components/general/ContainerLoad";
import { Button } from "@mui/material";
import { FormatListNumberedRounded } from "@mui/icons-material";
import { button_sx } from "../../../../../config/mui_style.config";
import EditPagesCorrespondance from "./popups/EditPagesCorrespondance";

export interface PopupUpdateInvProps extends ServerProps{
    selected_data : any, 
    data : any, 
    handleClose : () => void,
    setData : any, 
    setManager : React.SetStateAction<any>, 
    filtering_data : any
}

interface PopupUpdateInvManager {
    create_invoice : boolean, 
    supposed_supplier : string,
    load : boolean, 
    edit_pages : boolean
}



export default function PopupUpdateInv(props:PopupUpdateInvProps) {
    const user_account = useSelector(account_user)
    const dispatch = useDispatch()
    const [form, setForm] = useState<any>(props.selected_data)
    const [manager, setManager] = useState<PopupUpdateInvManager>({
        create_invoice : false, 
        supposed_supplier : "", 
        load : false, 
        edit_pages : false
    })

    
    const last_status = form.status_log.filter((st:any) => st.status !== "sent").sort((a:any, b:any) => new Date(b.date).getTime() - new Date(a.date).getTime())[0]
    const selected_data_index = props.filtering_data.findIndex((dt:any) => dt === form._id)

    //const [manager, setManager] = useState<any>({})
    const handleChangeInput = (e:any) => {
        /*const last_status_log = form.status_log.sort((a:any, b:any) => new Date(b.date).getTime() - new Date(a.date).getTime())[0] 

        if(last_status_log.status === "sent") {
            return dispatch(addRequestStatus({status : false, message : "Vous ne pouvez pas modifier une facture envoyée au comptable"}))
        }*/
        return setForm((state:any) => {return{...state, [e.target.name] : e.target.value}})
    }

    const config = [
        {
            id : "MOD-00", 
            name: "Identification", 
            svg : "id-badge", 
            component : <Identification
                server = {props.server}
                selected_data = {form}
                selected_index={0}
                mode = "update"
                handleChangeInput = {handleChangeInput}
                setData = {(type:string, value:any) => {
                    if(type === "type") {
                        setForm((state:any) => {
                            return {
                                ...state, 
                                type : value, 
                                id_affairs : state.id_affairs.map((dt:any) => {
                                    return {
                                        ...dt, 
                                        ht : value === "credit-note" ? Math.abs(dt.ht) * -1 : Math.abs(dt.ht), 
                                        tva : value === "credit-note" ? Math.abs(dt.tva) * -1 : Math.abs(dt.tva)
                                    }
                                }), 
                                total_tva : value === "credit-note" ? Math.abs(state.total_tva) * -1 : Math.abs(state.total_tva), 
                                total_ttc : value === "credit-note" ? Math.abs(state.total_ttc) * -1 : Math.abs(state.total_ttc), 
                            }})
                    }
                }}
                handleSupposedSupplier={(e:string) => {
                    setManager(state => {return{...state, supposed_supplier : e, create_invoice : true}})
                }}
            />
        }, 
        {
            id : "MOD-01", 
            name : "Dates",
            svg : "calendar-days", 
            component : <Dates
                selected_data_toMap = {form}
                selected_data = {form}
                handleChangeInput = {handleChangeInput}
                setData = {setForm}
            />
        }, 
        {
            id : "MOD-02", 
            svg : "money-bill", 
            name : "Totaux",
            component : <Totals
                setData = {(type:string, value:any) => {
                    if(type === "total_ht") {
                        setForm((state:any) => {
                            if(state.id_affairs.length > 1) {
                                dispatch(addRequestStatus({
                                    status : true, 
                                    message : "Suite à cette modification, l'édition des affaires a été réinitialisée.", 
                                }))
                            }
                            return {
                                ...state, 
                                id_affairs : [{id : state.id_affairs[0].id, ht : parseFloat(value), tva : state.total_tva}],
                                total_ttc : parseFloat(value) + state.total_tva
                            }})
                    }
                    if(type === "total_tva") {
                        setForm((state:any) => {
                            if(state.id_affairs.length > 1) {
                                dispatch(addRequestStatus({
                                    status : true, 
                                    message : "Suite à cette modification, l'édition des affaires a été réinitialisée.", 
                                }))
                            }
                            return {
                                ...state, 
                                id_affairs : [{...state.id_affairs[0], tva : parseFloat(value)}],
                                total_ttc : state.id_affairs[0].ht + parseFloat(value), 
                                total_tva : parseFloat(value)
                            }
                        })
                    }
                }}
                selected_data_toMap = {form}
                selected_data = {form}
                handleChangeInput = {handleChangeInput}
            />
        }, 
        {
            id : "MOD-03", 
            name : "Désignation", 
            svg : "comments",  
            component : <Comment
                selected_data_toMap = {form}
                handleChangeInput = {handleChangeInput}
            />
        }, 
    ]

    //console.log(form)

    const handleUpdate = () => {
        //dispatch(setLoaderStatus({status : true}))

        if(!checkInvStatus(form)) {
            dispatch(setLoaderStatus({status : false}))
            return dispatch(addRequestStatus({status : false, message : "Veuillez remplir tous les champs."}))
        }

        const obj1 = {...props.selected_data, id_supplier : props.selected_data.id_supplier._id}
        const obj2 = {...form, id_supplier : form.id_supplier._id}
        props.server.post("/invoicing/update-inv", {...Object.fromEntries(Object.entries(ObjectUtils.getObjectDifference(obj1,obj2))), id_inv : props.selected_data._id})
        .then(res => {
            dispatch(setLoaderStatus({status : false}))
            props.setData((state : any) => {
                return state.map((dt:any) => {
                    if(dt._id === props.selected_data._id) {
                        return res.data.data
                    }
                    return dt
                })
            
            })
            props.handleClose()
            return dispatch(addRequestStatus({
                status : true, 
                message : "Facture modifiée avec succès"
            }))
        })
        .catch(err => {
            dispatch(setLoaderStatus({status : false}))
            return dispatch(addRequestStatus(err.response.data))
        })
    }

    useEffect(() => {
        if(props.data.find((dt:any) => dt._id === props.selected_data._id).status_log.length !== form.status_log.length) {
            setForm((state:any) => {
                return {
                    ...state, 
                    status_log : props.data.find((dt:any) => dt._id === props.selected_data._id).status_log
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])

    const handleChangeInvoice = (status:"next"|"prev") => {
        var current_index = selected_data_index

        if(status === "next") {
            current_index++
        } else {
            current_index--
        }

        const get_id = props.filtering_data[current_index]
        setManager(state => {return{...state, load : true}})
        props.server.post('/invoicing/select-inv', {id_inv : get_id})
        .then(res => {
            const data = {
                ...res.data.data,
                url : server_host(res.data.data.url)
            }
            //dispatch(setLoaderStatus({status : false}))
            setForm(data)

            setTimeout(() => {
                setManager(state => {return{...state, load : false}})
            }, 500)
        })
        .catch(err => {
            setManager(state => {return{...state, load : false}})
            dispatch(addRequestStatus(err.response.data))
        })
    }

    return (
        <React.Fragment>
            {
                manager.edit_pages ?
                    <EditPagesCorrespondance
                        data = {form}
                        handleClose = {() => setManager(state => {return{...state, edit_pages : false}})}
                    />
                : 
                    null
            }
             {
                manager.create_invoice ? 
                    <SupplierManager
                        supposed_supplier = {{name : manager.supposed_supplier?? "" }}
                        server = {props.server}
                        handleAdd = {(e) => {
                            setForm((state:any) => {return{...state, id_supplier : e._id, name_supplier : e.name}})
                        }}
                        handleClose = {() => setManager(state => {return{...state, create_invoice : false}})}
                    />
                : null
            }
            <BasePopup handleClose = {props.handleClose} nameClass="popup-inv-update">
                <ContainerLoad load = {manager.load}>
                    <div className = "popup-inv-update--header">
                        {
                            selected_data_index > 0 ?
                                <div 
                                    onClick = {() => handleChangeInvoice("prev")}
                                    className="arrows arrows--prev"
                                >
                                    <Icons name = "arrow-down" color = "#116633"/>
                                <p>Précédent</p>
                                </div>
                            : null
                        }


                        <div className="actions">
                            <div 
                                onClick = {() => props.setManager((state:any) => {return {...state, comment : {status : true, mode : "pending", selected_data : [form._id]}}})}
                                className={last_status.status === "pending" ? "actions--pending" : ""}
                            >
                                <p>En attente</p>
                                {
                                    last_status.status === "pending" ? 
                                        <LastStatus st = {last_status}/>
                                    : null
                                }
                            </div>
                            <div 
                                onClick = {() => props.setManager((state:any) => {return {...state, comment : {status : true, mode : "validated", selected_data : [form._id]}}})}
                                className={last_status.status === "validated" ? "actions--validated" : ""}
                            >
                                <p>Valider</p>
                                {
                                    last_status.status === "validated" ? 
                                        <LastStatus st = {last_status}/>
                                    : null
                                }
                            </div>
                            <div 
                                onClick = {() => props.setManager((state:any) => {return {...state, comment : {status : true, mode : "refused", selected_data : [form._id]}}})}
                                className={last_status.status === "refused" ? "actions--refused" : ""}
                            >
                                <p>Refuser</p>
                                {
                                    last_status.status === "refused" ? 
                                        <LastStatus st = {last_status}/>
                                    : null
                                }
                            </div>
                        </div>

                        {
                            selected_data_index < props.filtering_data.length - 1 ?
                                <div 
                                    onClick = {() => handleChangeInvoice("next")}
                                    className="arrows arrows--next">
                                    <p>Suivant</p>
                                    <Icons name = "arrow-down" color = "#116633"/>
                                </div>
                            : null
                        }
                    </div>
                    <div className = "popup-inv-update--container">
                        <div className='preview'>
                            {
                                /*
                                
                                                            <Button
                                variant="contained"
                                color="primary"
                                //onClick = {() => setManager(state => {return {...state, change_status_popup : {...state.change_status_popup, status : true, selected_data : get_info_selected_data.map((sd:any) => sd._id)}}})}
                                startIcon={<FormatListNumberedRounded />}
                                sx={{
                                    ...button_sx, 
                                    backgroundColor: '#2196f3', // Couleur de fond normale
                                    '&:hover': {
                                    backgroundColor: '#0d47a1' // Couleur de fond au survol
                                    },
                                }}
                            >
                                Disposition des pages
                            </Button>
                                */
                            }
                            <iframe src={form.url} title="Web Page Viewer" className="iframe-container"/>
                        </div>
                        <div className = "update">
                            <CategorizerManager config = {config} rerender = {true} />
                            {
                                // ! !form.sent_to_accountant.status &&  (À paramétrer dans le back-end pour les factures envoyées au comptable)
                                Object.entries(ObjectUtils.getObjectDifference(form, props.selected_data)).length > 0 && user_account.infos.user_group === "direction" ? 
                                    <MainButton
                                        handleClick = {handleUpdate}
                                    >
                                        Modifier
                                    </MainButton>
                                : 
                                    null
                            }

                        </div>
                    </div>   
                </ContainerLoad>
            </BasePopup>
        </React.Fragment>
    )
}

interface LastStatusInterface {
    st : any
}

function LastStatus(props:LastStatusInterface) {
    return <div>
    <p>{`${props.st.comment.trim().length === 0 ? "Aucun commentaire" : props.st.comment}`}</p>
    <p>
        <span>{`Posté le ${moment(new Date(props.st.date)).format('DD/MM/YYYY')} à ${moment(new Date(props.st.date)).format('HH:mm')} `}</span>
        <span>{`Par ${props.st.user.firstname} ${props.st.user.lastname}`}</span>
    </p>
</div>
}